.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  background-color: #FFFFFF
}

.padding {
  padding: 10rem !important;
  margin-left: 200px
}

.card-no-border .card {
  border-color: #d7dfe3;
  border-radius: 4px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05)
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem
}

html body .dl {
  display: inline-block
}

.text-white {
  color: #ffffff !important
}

.font-light {
  font-weight: 300
}

.card-text:last-child {
  margin-bottom: 0
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem
}

.b-r {
  border-right: 1px solid rgba(120, 130, 140, 0.13)
}

.text-info {
  color: #1e88e5 !important
}

.display-6 {
  font-size: 36px
}

.mdi {
  display: inline-block;
  font-family: 'weathericons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.m-l-20 {
  margin-left: 20px
}

.m-b-0 {
  margin-bottom: 0px
}

small {
  font-size: 80%;
  font-weight: 400
}
h1 {
  color: green;
}
